import Card from '@/components/Card/Card.vue';
import { Icon, IconSize } from '@/components/Icon';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import ClientsBrandsSidebar from '@/views/Dashboard/Clients/ClientsBrandsSidebar.vue';
import FetchData from '@/components/DataProvider/FetchData.vue';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import { ImageView, ImageSize } from '@/components/ImageView';
import VueStore from '@/store';
import FilterToggler from '@/components/Filters/FilterToggler.vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useClient, useMobileView } from '@/composables';
import { useRouteQuery } from '@/composables';
import { FILTER_DEBOUNCE } from '@/constants/filters';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { mdiChevronLeft, mdiHomeOutline } from '@mdi/js';
import WithSideNavigations from '@/layouts/WithSideNavigations.vue';
const { useGetters } = createNamespacedHelpers('user');
export default defineComponent({
    name: 'ClientDetails',
    components: {
        Card,
        Icon,
        Tabs,
        Tab,
        ClientsBrandsSidebar,
        FetchData,
        IconSpinner,
        FilterToggler,
        ImageView,
        WithSideNavigations,
    },
    async beforeRouteEnter(to, from, next) {
        const promises = [
            VueStore.dispatch('client/fetchClientDetails', to.params['id']),
        ];
        if (VueStore.getters['user/hasAdminRights']) {
            promises.push(VueStore.dispatch('attachment/fetchAttachments', {
                id: to.params['id'],
                target: 'organizations',
                type: 'document',
            }));
        }
        await Promise.all(promises);
        next();
    },
    setup(_, ctx) {
        const { client } = useClient();
        const { routeQuery } = useRouteQuery(ctx);
        const { hasAdminRights } = useGetters(['hasAdminRights']);
        const { isMobileView } = useMobileView();
        const sidebarOpen = ref(false);
        const listUpdatesCounter = ref(0);
        const computedQuery = computed(() => {
            return {
                ...routeQuery.value,
                clientId: client.value.id,
            };
        });
        return {
            IconSize,
            mdiChevronLeft,
            mdiHomeOutline,
            FILTER_DEBOUNCE,
            ImageSize,
            client,
            listUpdatesCounter,
            hasAdminRights,
            sidebarOpen,
            computedQuery,
            isMobileView,
        };
    },
});
